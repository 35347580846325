import React from "react"
import { graphql, Link } from "gatsby"

import { StaticImage } from 'gatsby-plugin-image'

import '../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import FeatureSide from '../../../components/feature-side'
import FeatureRelated from '../../../components/feature-related'

const Home = ({ data }) => {
  return (
    <Layout>
      <SEO
        pagetitle="自分は自分のままでいい！勇気をもらえる名言！"
        pagepath="/feature/05/"
      />
      <main>
        <div className="l-feature__mv">
          <div className="l-feature__bg">
            <StaticImage
							src="../../../assets/images/feature/jibunmama01.jpg"
							alt="自分は自分のままでいい！"
						/>
          </div>
          <div className="l-feature__above">
            <h3 className="l-feature__hdg">自分は自分のままでいい！勇気をもらえる名言！</h3>
          </div>
        </div>
        <div className="l-feature">
          <div className="l-wrap">
            <div className="l-feature__item">
              <div className="l-feature__box">

                <div className="l-feature__main">

                  <div className="l-feature__trunk">
                    <div className="l-feature__paragraph">
                      <h2 className="l-feature__lead">
                        <span>落ち込んでしまう、そんな連続が人生なのかも。</span>
                        <span>落ち込むことが普通なら、わたしは立ち上がる力を身に着けよう。<br />
                        より強く、優しくなるために。わたしたちの背中をそっと押してくれる名言を紹介します。</span>
                        <span>
                          迷ったり、悩んだり、自分に喝を入れたいときに。ぜひこの言葉たちに戻ってきてください。
                        </span>
                      </h2>
                    </div>

                    <div className="l-feature__paragraph">
                      <div className="l-feature__wrap">
                        <div className="l-feature__num">01.</div>
                        <div className="l-feature__quote">
                          {data.post1.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>ニーチェのこの言葉を鑑みると、私たちは本当の友達は何人持っているでしょうか？</p>
                        <p>私たちは、<span className="marker-b">無意識の恐怖から逃れるために友人を求めてしまいがち</span>です。</p>
                        <p>しかし私たちの人生を豊かにするような考え方や知恵に気づくのは<span className="f-l">決まって孤独の時</span>です。</p>
                        <p>人と群れることを常とする人には、そのような有意義な時間は訪れません。</p>
                        <p>最初は恐怖を伴いますが、<span className="marker-b">意識的に孤独になる時間</span>を作りましょう。</p>
                        <p>そうでないと、ニーチェの言うように、自分が危険な状態になっていることすら理解できない可能性もありますから。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">02.</div>
                        <div className="l-feature__quote">
                          {data.post2.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>アリストテレスの皮肉の利いた言葉です。</p>
                        <p>確かに振り返ってみると、<span className="f-l">本当に価値ある友人、親友と呼べる友達は数えるほど</span>しかいません。</p>
                        <p>本来であれば、そのような大切な友人との時間を長く持つべきです。</p>
                        <p>しかし私たちは、多くの友人をとっかえひっかえ囲っています。</p>
                        <p>それでは本当に大切な友との友情を育むことができません。</p>
                        <p>アリストテレスの神の言葉を忠実に実行するならば、本当に価値ある友人にこそ、時間を使うべきだということです。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">03.</div>
                        <div className="l-feature__quote">
                          {data.post3.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>この世界には多くの人がいます。</p>
                        <p><span className="marker-y">自分よりも劣る人がいる一方、たくさんの自分より優れている人</span>がいます。</p>
                        <p>これはすべての人間に当てはまることです。</p>
                        <p>であるならば、<span className="f-l">人と比較することに何の意味がある</span>でしょうか？</p>
                        <p>一言で言うならば、自分より劣っている人もいるし、自分よりも優れている人もいる。これ以上でもこれ以下でもありません。</p>
                        <p>そして他者と自分を比較する事は、総じて<span className="f-l">自分に対する卑下</span>につながります。</p>
                        <p>ならば、そんな愚かな行為はやめましょう。</p>
                        <p>そして有意義なことに時間を使いましょう。</p>
                        <p>ビルゲイツの言うように、自分を誰とも比較せずただ自分の信じる道を歩むのです。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">04.</div>
                        <div className="l-feature__quote">
                          {data.post4.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>「地位財」と「非地位財」という概念をご存知でしょうか？</p>
                        <p>「非地位財」とは、<span className="marker-b">他人との比較とは関係なく満足が得られる財</span>のこと。健康、自主性、社会への帰属意識、良質な環境、自由、愛情がそれにあたります。</p>
                        <p>「地位財」とは、<span className="marker-b">他人との比較によって満足が得られる財</span>のことで、お金、社会的地位、モノなどがその代表です。</p>
                        <p>様々なメタ分析で、私たちに本当の幸福をもたらすのは<span className="f-l">「非地位財」</span>だとわかっています。</p>
                        <p>しかし、私たちは現代の資本主義教ともいえる価値観に支配されているので、無意識のうちに「地位財」を求めてしまいます。</p>
                        <p>そんな時は、このトーマスエジソンの言葉を思い出しましょう。</p>
                        <p>「地位財」への誘惑に打ち勝ち、トーマスエジソンが享受したであろう幸福を手に入れましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">05.</div>
                        <div className="l-feature__quote">
                          {data.post5.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>偉大な音楽家モーツアルト。</p>
                        <p>彼らしい名言がこれです。</p>
                        <p>私たちは、往々にして、<span className="f-l">他者の批判や賞賛</span>に目を奪われます。</p>
                        <p>そしてなぜか、それが<span className="marker-b">正しい評価なのだと盲信</span>しています。</p>
                        <p>誤った習性により、<span className="f-l">自分の才能や感性を無駄に</span>していませんか？</p>
                        <p>他人の意見を気にせずに、自分の感性のみを指針として人生を歩むことは、<span className="marker-y">支えをなくしたまま暗中模索</span>するような恐怖が伴います。</p>
                        <p>しかし、その道に飛び込むべきです。ときには恐怖に足がすくむこともあるでしょう。そんな時はこのモーツァルトの言葉を思い出しましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">06.</div>
                        <div className="l-feature__quote">
                          {data.post6.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>波瀾万丈の人生を送った詩人・作家オスカー・ワイルド。</p>
                        <p>彼の人生を象徴するような名言です。</p>
                        <p>私たちは、<span className="f-l">エゴによってこの人生を操縦</span>されています。</p>
                        <p>エゴの行動指針はたった1つ、それは<span className="marker-b">自分のみの幸福を求める</span>ことです。</p>
                        <p>そのエゴの行動指針によるならば、私たちは多かれ少なかれこのオスカーワイルドと言う状態にあります。</p>
                        <p>しかし、オスカーワイルドほど真摯に自分と向き合える人はなかなかいません。</p>
                        <p>自分と向き合う事は、<span className="marker-y">自分の恥ずかしさや、自分の至らなさや、自分の未熟さとも対峙する</span>ことになり、大変苦痛を伴います。</p>
                        <p>逃げてはなりません。オスカーワイルドのように自分探しの探求を続け、そして実生活での行動に落とし込みましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">07.</div>
                        <div className="l-feature__quote">
                          {data.post7.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>仏教やヨガの考え方で、<span className="f-l">今この場所に集中しろ</span>という教えがあります。</p>
                        <p>私たちには未来はありません。なぜなら<span className="marker-b">未来はまだ来ていない</span>からです。</p>
                        <p>私たちには過去はありません。なぜなら<span className="marker-b">過去はもう過ぎ去った</span>からです。</p>
                        <p>私たちが、影響を及ぼすことができるのは今この瞬間のみです。</p>
                        <p>だからこそ過去にくよくよしたり、未来を逡巡したりするのは愚かの極みです。</p>
                        <p>智者はそのことをよく知り、自分ができる最大限のことをその場でやることを意識します。</p>
                        <p>セオドアルーズベルトのように。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">08.</div>
                        <div className="l-feature__quote">
                          {data.post8.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>ドイツのショーペンハウアーの知恵のある言葉。</p>
                        <p>人間は<span className="marker-b">他人の影響を知らず知らずの間に受ける</span>ものです。</p>
                        <p>言葉遣い、立ち振る舞い、善悪の判断、道徳規範。</p>
                        <p>自分が意識するしないにかかわらず他者の影響を受けこれらの大切な判断をゆだねてしまいます。</p>
                        <p>人間は、所属する組織によって定義されると言う言葉もあります。</p>
                        <p>それほどまでに他者の影響は強いのです。</p>
                        <p>ショーペンハウアーの言葉は、そんな他人の影響をに一石を投じる力があります。</p>
                        <p>自分の人生を生き抜くためには、<span className="marker-b">自分の信念</span>が欠かせません。</p>
                        <p>義理を重んじるのか、 友情を重んじるのか、正しい生き方を人生に挑むのか。</p>
                        <p>あなたにとっての<span className="marker-b">r正しい人生</span>があるはずです。 </p>
                        <p>その舵取りは他人に委ねてはいけません。 </p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">09.</div>
                        <div className="l-feature__quote">
                          {data.post9.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>宮本武蔵などの作品で知られる作家、吉川英治。</p>
                        <p>この言葉には力があります。</p>
                        <p><span className="marker-b">過去をくよくよすることの無意味さへの理解</span>と、<span className="marker-b">新しい挑戦への勇気</span>をもらえます。</p>
                        <p>始めるのが遅かった、今からではもう遅い。 </p>
                        <p>私たちは往々にして、そんなことを考えてしまいます </p>
                        <p>吉川のこの言葉を思い出しましょう。 </p>
                        <p>今日が人生で一番若い日 。</p>
                        <p>これ以降の時間を無駄にしないために、今すぐ行動に移るのです。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">10.</div>
                        <div className="l-feature__quote">
                          {data.post10.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>私たちには世界を変えることができません。</p>
                        <p>政治・経済・社会などにおいて私たちは憤りを覚えることがあります。</p>
                        <p>自分の思い通りにいかなかった時、それに対して抵抗してしまいます。</p>
                        <p>その行動は、愚かだと言うほかありません。</p>
                        <p><span className="marker-b">r自分たちの力が及ぶ範囲と及ばない範囲</span>を知るべきです。</p>
                        <p>世界は変えられない。これは純然たる事実です。</p>
                        <p>変えられないものに時間と労力を割くのはいけません。<span className="f-l">自分が変えられるもの</span>にのみ注力すべきです。</p>
                        <p>社会や、世界を嘆くのではなく、そのような社会や世界を見なければならない自分の心を反省しましょう。</p>
                        <p>そして、ポジティブに作り替えるのです。</p>
                      </div>

                    </div>
                  </div>
                  <div className="l-feature__trunk">
                    <FeatureRelated />
                  </div>
                </div>

                <div className="l-feature__data">
                  <FeatureSide data={data} />
                </div>

              </div>

            </div>

          </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    post1: allContentfulBlogPost(
      filter: {id: {eq: "34aa73b0-115f-51e5-bce3-f7b262e8953e"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post2: allContentfulBlogPost(
      filter: {id: {eq: "5727636f-b1aa-5861-af0f-a71b6a9d1198"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post3: allContentfulBlogPost(
      filter: {id: {eq: "467283ac-c061-5678-9a3a-60e9532d626e"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post4: allContentfulBlogPost(
      filter: {id: {eq: "1766a2ca-92ab-5ce5-bc4d-0a506081c19e"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post5: allContentfulBlogPost(
      filter: {id: {eq: "e85fbeb5-873f-50c8-897d-1e7297de798b"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post6: allContentfulBlogPost(
      filter: {id: {eq: "3cca80cd-38eb-57b0-880f-b54e999f8dbb"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post7: allContentfulBlogPost(
      filter: {id: {eq: "c85f487a-0f51-5fe9-8cc7-440b0d2bd954"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post8: allContentfulBlogPost(
      filter: {id: {eq: "c364907b-29ba-56b1-966d-380481668e62"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post9: allContentfulBlogPost(
      filter: {id: {eq: "71fea22f-e657-5403-b5a4-ca2a0f453f8c"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post10: allContentfulBlogPost(
      filter: {id: {eq: "6ec233c4-22cd-5708-9048-57230761be34"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    cate: allContentfulCategory {
      edges {
        node {
          informations {
            informations
          }
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    allCate: allContentfulCategory(
      sort: {fields: sort, order: DESC},
      limit: 20
    ) {
      edges {
        node {
          name
          slug
          id
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    tags: allContentfulTags {
      edges {
        node {
          title
          slug
          id
        }
      }
    }
  }
`

export default Home